import React from 'react'
import { Modal, Input } from 'antd'


const SearchModal = (props) => {

    const handleSearch = () => {
        props.search()
    }

    return (
        <Modal cancelButtonProps={{ type: 'ghost' }} bodyStyle={{ backgroundColor: '#1F1F1F' }} centered okText='Search' title='Search' visible={props.showSearchModal} onOk={handleSearch} onCancel={() => props.setShowSearchModal(false)}>
            <Input className='form-input' value={props.query} style={{ backgroundColor: '#1f1f1f', borderColor: '#434343', borderWidth: 1, color: '#dedede' }} onChange={(e) => props.setQuery(e.target.value)} placeholder='Query' />
        </Modal>
    )
}

export default SearchModal
