import { useState } from 'react'
import { Form, Input, Button, Space } from 'antd'
import { auth } from '../../firebase'

const ResetPassword = (props) => {

    const [ email, setEmail ] = useState('')
    const [ isLoading, setIsLoading ] = useState('')

    const reset = () => {
        setIsLoading(true)
        auth
            .sendPasswordResetEmail(email)
            .then(() => alert('Password reset email sent'))
            .catch((e) => alert(e))
        setIsLoading(false)
    }

    return (
        <div className="login-container">
            <h1>Reset your password</h1>
            <p className='small-text'>Please enter your information to continue</p>
            <Form>
                <Form.Item>
                    <Input className="ssp" onChange={(e) => setEmail(e.target.value)} style={{ width: '55%', color: 'white', backgroundColor: '#424447', borderWidth: 0 }} placeholder="Email" />
                </Form.Item>
                <Form.Item>
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <Button loading={props.isLoading} className="ssp" onClick={reset} style={{ width: '55%' }} type="primary">Reset Password</Button>
                        <div className="ssp"><span className="ssp link" onClick={() => props.setShowReset(false)}>Back to login</span></div>
                    </Space>
                </Form.Item>
            </Form>
        </div>
    )
}

export default ResetPassword

