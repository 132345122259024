import { useState, useEffect } from 'react'
import { Modal, Form, Input, Upload, message, Radio, Tooltip, Dropdown, Menu, Button } from 'antd'
import { InboxOutlined } from '@ant-design/icons';
import { MdOutlineApartment } from 'react-icons/md'

const { Dragger } = Upload
const { TextArea } = Input

const AddLocationModal = (props) => {

    const [ locName, setLocName ] = useState('')
    const [ locDescription, setLocDescription ] = useState('')
    const [ locCoords, setLocCoords ] = useState('')
    const [ typeOfPlace, setTypeOfPlace ] = useState('')

    const validCoords = /-?[0-9]+.[0-9]+,\s?-?[0-9]+.[0-9]+/

    const onAdd = () => {
        setLocName('')
        setLocCoords('')
        setLocDescription('')
        setTypeOfPlace('')
        props.addLocation(locName, locDescription, locCoords, typeOfPlace)
    }

    const getTypeOfPlace = () => {
        switch(typeOfPlace) {
            case 'home': return 'House'
            case 'school': return 'School'
            case 'restaurant': return 'Restaurant'
            case 'business': return 'Business'
            case 'prison': return 'Prison'
            case 'apartment': return 'Apartment'
            case 'mill/factory': return 'Factory'
            case 'attraction': return 'Attraction'
            case 'hospital': return 'Hospital'
            case 'other': return 'Other'
            default: return 'Choose a type'
        }
    }

    return (
        <Modal cancelButtonProps={{ type: 'ghost' }} bodyStyle={{ backgroundColor: '#1F1F1F' }} centered okText='Add Location' title="Add a Location" visible={props.showAddLocationModal} onOk={onAdd} onCancel={() => props.setShowAddLocationModal(false)}>
            <Form labelAlign='left'>
                <Form.Item required label="Location Name">
                    <Input value={locName} className='form-input' style={{ backgroundColor: '#1f1f1f', borderColor: '#434343', borderWidth: 1, color: '#b0b0b0' }} onChange={(e) => setLocName(e.target.value)} placeholder='Location Name' />
                </Form.Item>
                <Form.Item required label="Address/Coordinates">
                    <Input value={locCoords} className='form-input' style={{ backgroundColor: '#1f1f1f', borderColor: '#434343', borderWidth: 1, color: '#dedede' }} onChange={(e) => setLocCoords(e.target.value)} placeholder='Coordinates' />
                </Form.Item>
                <Form.Item required label="Description">
                    <TextArea value={locDescription} className='form-input' autoSize style={{ backgroundColor: '#1f1f1f', borderColor: '#434343', borderWidth: 1, color: '#dedede' }} onChange={(e) => setLocDescription(e.target.value)} placeholder='Description' />
                </Form.Item>
                <Form.Item required label="Type">
                    <Dropdown 
                        overlay={(
                            <Menu className='location-type-dropdown'>
                                <Menu.Item onClick={() => setTypeOfPlace('home')}>House</Menu.Item>
                                <Menu.Item onClick={() => setTypeOfPlace('school')}>School</Menu.Item>
                                <Menu.Item onClick={() => setTypeOfPlace('restaurant')}>Restaurant</Menu.Item>
                                <Menu.Item onClick={() => setTypeOfPlace('business')}>Business</Menu.Item>
                                <Menu.Item onClick={() => setTypeOfPlace('prison')}>Prison</Menu.Item>
                                <Menu.Item onClick={() => setTypeOfPlace('apartment')}>Apartment</Menu.Item>
                                <Menu.Item onClick={() => setTypeOfPlace('mill/factory')}>Factory</Menu.Item>
                                <Menu.Item onClick={() => setTypeOfPlace('attraction')}>Attraction</Menu.Item>
                                <Menu.Item onClick={() => setTypeOfPlace('hospital')}>Hospital</Menu.Item>
                                <Menu.Item onClick={() => setTypeOfPlace('other')}>Other</Menu.Item>
                            </Menu>
                        )}
                        placement={'bottomCenter'}
                        value={typeOfPlace}
                    >
                        <Button style={{ color: typeOfPlace === '' ? '#4d4d4d' : '#dedede', backgroundColor: '#1f1f1f', borderColor: '#434343', borderWidth: 1, width: '100%', textAlign: 'left' }}>{getTypeOfPlace()}</Button>
                    </Dropdown>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AddLocationModal
