// Import the functions you need from the SDKs you need
import firebase from "firebase";
import "firebase/firestore"

/***
 * 
 * @NOTE HOW TO USE FIRESTORE
 * 
 ************************************************************************
 * 
 * How to GET data from firestore:
 * 
 * const getUsers = async () => {
 *      const data = await db.collection('Users').get().then((qs) => {
 *           qs.forEach((doc) => {
 *               let d = doc.data()
 *               console.log(d)
 *           })
 *       })
 *   }
 * 
 ************************************************************************
 * 
 * How to PUT data to firestore:
 * 
 * const userRef = db.collection('Users').add({
 *    uid: userCredentials.user.uid,
 *    username: username,
 *    email: email,
 *    saved_jobs: [],
 * })
 *
 ************************************************************************
 *
 * How to UPDATE data to firestore:
 * 
 * const ref = await db.collection('Users').doc(id).update({
 *     name: "Ethan Shealey"
 * })
 * 
 */

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAdiMVfz4t9uOnUQXWKVW6xXhssR6lwLEs",
  authDomain: "lostandfoundv2-f65ff.firebaseapp.com",
  projectId: "lostandfoundv2-f65ff",
  storageBucket: "lostandfoundv2-f65ff.appspot.com",
  messagingSenderId: "149504278420",
  appId: "1:149504278420:web:1bfc24173a500cc5559981",
  measurementId: "G-9YC9B2RG94"
};


let app

if(firebase.apps.length === 0) {
    app = firebase.initializeApp(firebaseConfig)
}
else {
    app = firebase.app()
}

const auth = firebase.auth()
const db = firebase.firestore()

export { auth, db }