import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import style from './style.json'
import Geocode from "react-geocode";

const containerStyle = {
    height: '100vh',
    width: 'auto',
    postion: 'relative'
}

const Map = (props) => {

    const onClick = (location) => {
        props.onLocationFocus(location)
        props.setShowLocationModal(true)
    }

    return (
        <LoadScript
          googleMapsApiKey="AIzaSyB7LkYZdeJ6TtE8LGuwdd2oihWdr02NHdM"
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={props.coords}
            zoom={props.zoom}
            options={{fullscreenControl: false, mapTypeControl: false, styles: style}}
          >
              {
                  props.locations?.map((location, index) => {
                    /*
                    let lat = ''
                    let lng = ''
                    let r = /[a-zA-Z]/g;
                    if(location.coordinates[0] === '(' && location.coordinates[location.coordinates.length - 1] === ')') {
                      let temp = location.coordinates.split(',')
                      temp[0] = temp[0].split('(')[1]
                      temp[1] = temp[1].split('(')[1]
                      lat = temp[0]
                      lng = temp[1]
                    }
                    else if(r.test(location.coordinates)) {
                      fetch(`https://api.geoapify.com/v1/geocode/search?text=${location.coordinates}&apiKey=a760eb15a9a84787b72105162f21e647`)
                        .then(res => res.json())
                        .then(data => {
                          lat = data.features[0].properties.lat
                          lng = data.features[0].properties.lon
                          console.log(data, lat, lng)
                        })
                    }
                    else {
                      let temp = location.coordinates.split(',')
                      lat = temp[0]
                      lng = temp[1]
                    }
                    console.log('adding to map:', lat, lng)*/
                    return (
                      <Marker key={index} position={{ lat: parseFloat(location.coordinates.split(',')[0]), lng: parseFloat(location.coordinates.split(',')[1]) }} title={location?.name} onClick={() => { onClick(location)} } /> 
                    )
                  })
              }
          </GoogleMap>
        </LoadScript>
    )
}

export default Map
