import { useState } from 'react'
import { Form, Input, Button, Space, Tooltip, message } from 'antd'
import { auth, db } from '../../firebase'

const Register = (props) => {

    const [ username, setUsername ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ verifyPassword, setVerifyPassword ] = useState('')

    const register = () => {
        // if the input is valid continue
        if(username && password === verifyPassword) {
            // use auth object to create a new user 
            // on firebase
            auth
            .createUserWithEmailAndPassword(email, password)
            .then((creds) => {
                // update the new users displayName
                creds.user.updateProfile({ displayName: username })
                // set the user hook to the new user
                props.setUser(creds.user)
                // add a new user to the db
                const userRef = db.collection('Users').add({
                    uid: creds.user.uid,
                    username: username,
                    email: email,
                    isVerified: false
                })

                const payload = {
                    'service_id': 'service_qt31j3v',
                    'template_id': 'template_8ux1hkc',
                    'user_id': 'v8Ik5fdh34UpmBGzP',
                    'template_params': {
                        'email': email,
                        'username': username,
                        'date': new Date().toLocaleDateString()
                    }
                }
                

                fetch('https://api.emailjs.com/api/v1.0/email/send', {
                    method: 'POST',
                    body: JSON.stringify(payload),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
            })
            .catch(e => alert(e.message))
        }
        else {
            alert("Password do not match")
        }
    }

    return (
        <div className="login-container">
            <h1>Welcome to Lost and Found</h1>
            <p className='small-text'>Please create an account to continue</p>
            <Form>
            <Form.Item>
                    <Input className="ssp" onChange={(e) => setUsername(e.target.value)} style={{ width: '55%', color: 'white', backgroundColor: '#424447', borderWidth: 0 }} placeholder="Username" />
                </Form.Item>
                <Form.Item>
                    <Tooltip
                        trigger={['focus']}
                        title={<span>Email is strictly for identification, other users will not see this</span>}
                        placement='left'
                    >
                        <Input className="ssp" onChange={(e) => setEmail(e.target.value)} style={{ width: '55%', color: 'white', backgroundColor: '#424447', borderWidth: 0 }} placeholder="Email" />
                    </Tooltip>
                </Form.Item>
                <Form.Item>
                    <Input className="ssp" onChange={(e) => setPassword(e.target.value)} style={{ width: '55%', color: 'white', backgroundColor: '#424447', borderWidth: 0 }} placeholder="Password" type="password" />
                </Form.Item>
                <Form.Item>
                    <Input className="ssp" onChange={(e) => setVerifyPassword(e.target.value)} style={{ width: '55%', color: 'white', backgroundColor: '#424447', borderWidth: 0 }} placeholder="Renter Password" type="password" />
                </Form.Item>
                <Form.Item>
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <Button loading={props.isLoading} className="ssp" onClick={register} style={{ width: '55%' }} type="primary">Register</Button>
                        <div className="ssp">Already have an account? <span className="ssp link" onClick={() => props.setShowLogin(true)}>Login</span></div>
                    </Space>
                </Form.Item>
            </Form>
        </div>
    )
}

export default Register
