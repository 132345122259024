import { useState, useEffect } from 'react'
import { Modal, Carousel, Button, Tabs, Tooltip, Skeleton } from 'antd'
import { FaRegMap } from 'react-icons/fa'
import { CgNotes, CgDanger } from 'react-icons/cg'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { db } from '../../firebase'

const LocationModal = (props) => {
    
    const [ isReported, setIsReported ] = useState(false)

    useEffect(() => {
        setIsReported(r => props.location?.reports?.includes(props.user.username))
    }, [props.location])

    const toggleReported = async () => {
        console.log(props.location)
        const ref = await db.collection('Locations').doc(props.location?.id).update({
            reports: props.location?.reports ?
                props.location?.reports?.includes(props.user.username) ? 
                    props.location?.reports?.filter(v => v !== props.user.username) :
                    [ ...props.location?.reports, props.user.username ]
                 : [ props.user.username ]
        })
        props.updateLocation(props.location?.id)
        setIsReported(r => !r)
    }


    const getDirections = () => window.open(`https://www.google.com/maps/dir/?api=1&destination=${props.location?.coordinates}`, '_blank').focus()

    return (
        <Modal cancelButtonProps={{ type: 'ghost' }} bodyStyle={{ backgroundColor: '#1F1F1F' }} centered footer={
            [
                <>
                    <Tooltip title={ isReported ? "Remove Report" : "Report Demolished"}>
                            <Button type='link' onClick={toggleReported} icon={ isReported ? <AiOutlineCloseCircle style={{ fontSize: 23, marginTop: 1.5, color: '#d0d0d0' }} /> : <CgDanger style={{ fontSize: 23, marginTop: 1.5, color: '#fc654e' }} />} style={{ float: 'left' }} />
                    </Tooltip>
                    <span style={{ float: 'left', color: '#b0b0b0', marginTop: 4.5 }}> | Reported { props.location?.reports ? props.location?.reports?.length : '0' } time{ props.location?.reports ? props.location?.reports?.length !== 1 ? 's' : '' : 's'}</span>
                </>,
                <Button type='ghost' onClick={() => props.setShowLocationModal(false)}>Close</Button>,
                //props.location?.addedBy === props.user.username && <Button style={{ backgroundColor: '#1f1f1f' }} type='primary'>Update</Button>,
                <Button type='primary' onClick={getDirections}> Directions</Button>
            ]
        } title={ props.isLocationLoading ? <Skeleton active /> : props.location?.name } visible={props.showLocationModal} onCancel={() => props.setShowLocationModal(false)}>
            <FaRegMap style={{ fontSize: '20' }} className="coord-icon" />
            <br />
            <div className="location-body-section">
                <a href={`http://maps.google.com/maps?q=loc:${props.location?.coordinates}`} rel="noreferrer" target="_blank"><i>{props.location?.coordinates}</i></a>
            </div>
            { props.location?.description &&
              <>
                <br />
                <CgNotes style={{ fontSize: '20' }} className="description-icon" />
                <div className="location-body-section">
                    <p style={{ color: '#dedede' }}>{props.location?.description}</p>
                </div>
              </>
            }              
            <div className='credit'><i>Added by {props.location?.addedBy} on {new Date(props.location?.dateAdded).toLocaleDateString()}</i></div>
        </Modal>
    )
}

export default LocationModal
