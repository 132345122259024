import React, { useEffect } from 'react'
import { Button } from 'antd'
import { LogoutOutlined, RedoOutlined  } from '@ant-design/icons';
import { db } from '../../firebase'
const Unverified = (props) => {

    const refresh = () => {
        db.collection('Users').get().then(qs => {
            qs.forEach(doc => {
                if(doc.data().uid === props.user.uid) props.setUser(doc.data())
            })
        })
    }

    return (
        <div className='login-container'>
            <h1>Welcome to Lost and Found</h1>
            <p className='ssp'>This community is invite only so each user must be verified by an admin before viewing this site</p>
            <p className='ssp small-text' style={{ marginTop: 0 }}>An admin has been notified and will verify you as soon as possible</p>
            <Button type='danger' onClick={props.logout}><LogoutOutlined /> Logout</Button>{'   '}
            <Button type='primary' onClick={refresh}><RedoOutlined /> Refresh</Button>
        </div>
    )
}

export default Unverified
