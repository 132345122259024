import { useState, useEffect } from 'react'
import React from 'react'
import Map from '../Map'
import { auth, db } from '../../firebase'
import Unverified from '../Unverified'
import LocationModal from '../LocationModal'
import SearchModal from '../SearchModal'
import AddLocationModal from '../AddLocationModal'
import { Layout, Menu, Button, Dropdown, message, Spin, Tooltip } from 'antd'
import { LogoutOutlined, PlusOutlined, RightOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { FaSchool, FaHospital } from 'react-icons/fa'
import { IoBusinessSharp } from 'react-icons/io5'
import { GiFactory } from 'react-icons/gi'
import { BsFillQuestionSquareFill, BsHouseDoorFill } from 'react-icons/bs'
import { MdAttractions, MdFastfood } from 'react-icons/md'
import { RiHospitalLine, RiFilterFill, RiFilterOffFill } from 'react-icons/ri'
import { TbPrison } from 'react-icons/tb'
import { LoadingOutlined } from '@ant-design/icons';
import { MdOutlineApartment } from 'react-icons/md'

const fts = require('elasticlunr')

const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Content, Sider } = Layout
const { SubMenu } = Menu

const Home = ({ user, setUser }) => {

  // STATES
  const [ locations, setLocations ] = useState([])
  const [ isLoading, setIsLoading ] = useState(true)
  const [ showLocationModal, setShowLocationModal ] = useState(false)
  const [ showAddLocationModal, setShowAddLocationModal ] = useState(false)
  const [ focusedLocation, setFocusedLocation ] = useState(null)
  const [ isLocationLoading, setIsLocationLoading] = useState(false)
  const [ zoom, setZoom ] = useState(5);
  const [ coords, setCoords ] = useState({ lat: 35.97193956032148, lng: -79.9955014993799 })
  const [ showSearchModal, setShowSearchModal ] = useState(false)
  const [ query, setQuery ] = useState('')
  const [ filter, setFilter ] = useState('')

  useEffect(() => {
    getLocations()
  }, [])

  useEffect(() => {
    setLocations(locations => locations.filter(loc => loc.type === filter))
  }, [filter])

  useEffect(() => {
    if(!showLocationModal) setFocusedLocation(_ => null)
  }, [showLocationModal])

  const getLocations = () => {
    setIsLoading(true)
    db.collection('Locations').get().then(qs => {
      const locs = []
      qs.forEach(doc => {
        locs.push({ ...doc.data(), 'id': doc.id })
      })
      locs.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded))
      setLocations(_ => locs)
    })
    setIsLoading(false)
  }

  const logout = () => {
    auth.signOut()
    setUser(null)
  }

  const getLocationTypeIcon = (ty) => {
    switch(ty) {
        case 'home': 
            return (<BsHouseDoorFill />)
        case 'school':
            return (<FaSchool />)
        case 'school':
            return (<FaHospital />)
        case 'restaurant':
            return (<MdFastfood />)
        case 'business':
            return (<IoBusinessSharp />)
        case 'mill/factory':
            return (<GiFactory />)
        case 'attraction':
            return (<MdAttractions />)
        case 'hospital':
            return (<RiHospitalLine />)
        case 'prison':
            return (<TbPrison />)
        case 'apartment':
            return (<MdOutlineApartment />)
        default:
            return (<BsFillQuestionSquareFill />)
    }
  }

  const onLocationFocus = (location) => {
    setCoords({ lat: parseFloat(location.coordinates.split(',')[0]), lng: parseFloat(location.coordinates.split(',')[1]) })
    setZoom(15)
    setIsLocationLoading(true)
    const ref = db.collection('Locations').doc(location.id).get()
                .then((doc) => {
                    setFocusedLocation({ ...doc.data(), 'id': doc.id })
                    setShowLocationModal(true)
                    setIsLocationLoading(false)
                })
  }
  const updateLocation = async (id) => {
    const ref = db.collection('Locations').doc(id).get()
                .then((doc) => {
                    setFocusedLocation({ ...doc.data(), 'id': doc.id })
                })
  }

  const handleOpenSearch = () => {
      setQuery('')
      setShowSearchModal(true)
  }

  const clear_filter = () => {
    setFilter('')
    getLocations()
  }

  const search = () => {

    if(query === '') 
        return clear_search()

    let index = fts()

    index.addField('location_name')
    index.addField('location_description')
    index.addField('location_type')
    index.setRef('id')

    locations.forEach((loc) => {
        index.addDoc({
            location_name: loc.name,
            location_description: loc.description,
            location_type: loc.type,
            id: loc.id
        })
    })

    const results = index.search(query).map((loc) => { return loc.ref })

    if(results) {
        if(results.length === 0) {
            message.error('No results found')
            setQuery('')
        }
        else {
            setLocations(locations.filter((location) => results.includes(location.id) ))
            setShowSearchModal(false)
        }
    }
  }

  const clear_search = () => {
    setQuery('')
    getLocations()
  }

  const addLocation = (locName, locDescription, locCoords, locType) => {

    // VERIFY COORDS:
    let r = /[a-zA-Z]/g;
    if(locCoords[0] === '(' && locCoords[locCoords.length - 1] === ')') {
        locCoords = locCoords.slice(1, -1)
    }
    else if(r.test(locCoords)) {
        fetch(`https://api.geoapify.com/v1/geocode/search?text=${locCoords}&apiKey=a760eb15a9a84787b72105162f21e647`)
            .then(res => res.json())
            .then(data => {
                locCoords = '' + data.features[0].properties.lat + ',' + data.features[0].properties.lon
                const locationRef = db.collection('Locations').add({
                    name: locName,
                    description: locDescription,
                    coordinates: locCoords,
                    type: locType,
                    addedBy: user.username,
                    dateAdded: new Date().toLocaleDateString()
                })
                setShowAddLocationModal(false)
                getLocations()
                return
            })
            return
    }
    const locationRef = db.collection('Locations').add({
        name: locName,
        description: locDescription,
        coordinates: locCoords,
        type: locType,
        addedBy: user.username,
        dateAdded: new Date().toLocaleDateString()
    })
    setShowAddLocationModal(false)
    getLocations()
  }

  const menu = (
    <Menu triggerSubMenuAction='click'>
        { query ? <Menu.Item onClick={() => clear_search()}><CloseOutlined /> Clear Search</Menu.Item> : <Menu.Item onClick={() => handleOpenSearch()}><SearchOutlined /> Search</Menu.Item> }
        { filter !== '' ? 
            <Menu.Item onClick={() => clear_filter()}><RiFilterOffFill /> Clear Filter</Menu.Item> 
            : 
            <SubMenu icon={<RiFilterFill />} trigger={['hover']} title='Filters'>
                <Tooltip placement='right' title={'Houses'}>
                    <Menu.Item onClick={() => setFilter('home')}>
                        {getLocationTypeIcon('home')}
                    </Menu.Item>
                </Tooltip>
                <Tooltip placement='right' title={'Schools'}>
                    <Menu.Item onClick={() => setFilter('school')}>
                        {getLocationTypeIcon('school')}
                    </Menu.Item>
                </Tooltip>
                <Tooltip placement='right' title={'Restaurants'}>
                    <Menu.Item onClick={() => setFilter('restaurant')}>
                        {getLocationTypeIcon('restaurant')}
                    </Menu.Item>
                </Tooltip>
                <Tooltip placement='right' title={'Businesses'}>
                    <Menu.Item onClick={() => setFilter('business')}>
                        {getLocationTypeIcon('business')}
                    </Menu.Item>
                </Tooltip>
                <Tooltip placement='right' title={'Prisons'}>
                    <Menu.Item onClick={() => setFilter('prison')}>
                        {getLocationTypeIcon('prison')}
                    </Menu.Item>
                </Tooltip>
                <Tooltip placement='right' title={'Apartment'}>
                    <Menu.Item onClick={() => setFilter('apartment')}>
                        {getLocationTypeIcon('apartment')}
                    </Menu.Item>
                </Tooltip>
                <Tooltip placement='right' title={'Factories'}>
                    <Menu.Item onClick={() => setFilter('mill/factory')}>
                        {getLocationTypeIcon('mill/factory')}
                    </Menu.Item>
                </Tooltip>
                <Tooltip placement='right' title={'Attractions'}>
                    <Menu.Item onClick={() => setFilter('attraction')}>
                        {getLocationTypeIcon('attraction')}
                    </Menu.Item>
                </Tooltip>
                <Tooltip placement='right' title={'Hospitals'}>
                    <Menu.Item onClick={() => setFilter('hospital')}>
                        {getLocationTypeIcon('hospital')}
                    </Menu.Item>
                </Tooltip>
                <Tooltip placement='right' title={'Other'}>
                    <Menu.Item onClick={() => setFilter('other')}>
                        {getLocationTypeIcon('other')}
                    </Menu.Item>
                </Tooltip>
            </SubMenu>
        }
        <Menu.Item onClick={() => setShowAddLocationModal(true)}><PlusOutlined /> Add Location</Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={logout}><span className='logout-btn'><LogoutOutlined /> Logout</span></Menu.Item>
    </Menu>
  )

  return (
    <>
    {
      user.isVerified ? 
      <>
        <Layout>
            <Sider
            breakpoint="lg"
            collapsedWidth="0"
            style={{
                height: '100vh',
            }}
            >
                <div className="logo">Lost and Found</div>
                
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={['-1']} style={{ overflow: 'scroll', height: '79vh' }}>
                        { 
                            isLoading ?
                                <Spin indicator={loadingIcon} style={{ textAlign: 'center', width: '100%', marginTop: '25px' }} />
                            :
                                <>
                                    <Menu.Item disabled className='results'><i>{locations.length} location{ locations.length !== 1 && 's'}</i></Menu.Item>
                                    {
                                    locations.map((location, index) => (
                                        <Menu.Item icon={getLocationTypeIcon(location.type)} key={index} onClick={() => onLocationFocus(location)}>{location.name}</Menu.Item>
                                    ))}
                                </>
                        }
                    </Menu>
                
                    <Dropdown overlay={menu} placement="topCenter" trigger={[ 'hover' ]}>
                        <Button type='primary' style={{ width: '100%', borderRadius: 0, height: '10vh' }}>Menu <RightOutlined /></Button>
                    </Dropdown>
            </Sider>
            <Layout>
                <Content>
                  <AddLocationModal addLocation={addLocation} showAddLocationModal={showAddLocationModal} setShowAddLocationModal={setShowAddLocationModal} user={user} />
                  <SearchModal query={query} setQuery={setQuery} setShowSearchModal={setShowSearchModal} showSearchModal={showSearchModal} search={search} />
                  <LocationModal getLocationTypeIcon={getLocationTypeIcon} updateLocation={updateLocation} location={focusedLocation} isLocationLoading={isLocationLoading} showLocationModal={showLocationModal} setShowLocationModal={setShowLocationModal} user={user} />
                  <Map locations={locations} zoom={zoom} coords={coords} onLocationFocus={onLocationFocus} setShowLocationModal={setShowLocationModal} />
                </Content>
            </Layout>
        </Layout>
      </>
      :
      <Unverified logout={logout} user={user} setUser={setUser} />
    }
    </>
  )
}

export default Home