import { useState } from 'react'
import Login from '../Login'
import Register from '../Register'

const Auth = (props) => {

    const [ showLogin, setShowLogin ] = useState(true)

    return (
        <div>
            { showLogin ? <Login setUser={props.setUser} setShowLogin={setShowLogin} /> : <Register setUser={props.setUser} setShowLogin={setShowLogin} /> }
        </div>
    )
}

export default Auth