import { useState } from 'react'
import { Form, Input, Button, Space } from 'antd'
import { auth, db } from '../../firebase'
import ResetPassword from '../ResetPassword'

const Login = (props) => {

    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ isLoading, setIsLoading ] = useState('')
    const [ showReset, setShowReset ] = useState(false)

    const login = () => {
        setIsLoading(true)
        auth.signInWithEmailAndPassword(email, password)
            .then(creds => {
                db.collection('Users').get().then(qs => {
                    qs.forEach(doc => {
                        if(doc.data().email === email) {
                            props.setUser(doc.data())
                        }
                    })
                })
            })
            .catch(e => {
                console.log(e)
            })
        setIsLoading(false)
    }

    return (
        <>
        { 
            showReset ? 
            <ResetPassword setShowReset={setShowReset} />
            :
            <div className="login-container">
                <h1>Welcome to Lost and Found</h1>
                <p className='small-text'>Please sign in to continue</p>
                <Form>
                    <Form.Item>
                        <Input className="ssp" onChange={(e) => setEmail(e.target.value)} style={{ width: '55%', color: 'white', backgroundColor: '#424447', borderWidth: 0 }} placeholder="Email" />
                    </Form.Item>
                    <Form.Item>
                        <Input className="ssp" onChange={(e) => setPassword(e.target.value)} style={{ width: '55%', color: 'white', backgroundColor: '#424447', borderWidth: 0 }} placeholder="Password" type="password" />
                    </Form.Item>
                    <Form.Item>
                        <Space direction="vertical" style={{ width: '100%' }}>
                            <Button loading={props.isLoading} className="ssp" onClick={login} style={{ width: '55%' }} type="primary">Log In</Button>
                            <div className="ssp">Don't have an account? <span className="ssp link" onClick={() => props.setShowLogin(false)}>Create an Account</span> |  <span className="ssp link" onClick={() => setShowReset(true)}>Forgot Password?</span></div>
                        </Space>
                    </Form.Item>
                </Form>
            </div>
        }
        </>
    )
}

export default Login
