import { useState } from 'react'
import Auth from './Components/Auth'
import Home from './Components/Home'
import usePersistentData from './Hooks/PersistData'
import 'antd/dist/antd.min.css'

const App = () => {

  const [ user, setUser ] = usePersistentData('user', null)

  return (
    <>
        {
          user === null ? <Auth setUser={setUser}/> : <Home user={user} setUser={setUser} />
        }
    </>
  )
}

export default App;
